<template>
<div>
  <b-container fluid>
      <b-row>
        <b-col lg="12" sm="12">
          <iq-card>
            <br>
            <template>
                <b-overlay :show="loading">
                  <b-col lg="8" offset-lg="2">
                      <b-row>
                        <b-col lg="12" sm="12" class="p-0">
                          <div class="card card w-100 text-center">
                              <div class="card-inside-title font-weight-bold text-capitalize p-1 bg-light text-black">
                                <h5 class="font-weight-bold text-capitalize text-center bg-light p-1 badge-light" style="color: green!important;font-size: 20px!important;"> {{ $t('admission_form.gen_inf') }}</h5>
                              </div>
                          </div>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col lg="12" sm="12" class="p-0">
                            <table class="table">
                              <tbody>
                                  <tr>
                                    <th>{{ $t('user.namel') }}</th>
                                    <td>{{ ($i18n.locale=='bn') ? profile.name_bn : profile.name }}</td>
                                    <th>{{ $t('externalUserIrrigation.father_name') }}</th>
                                    <td>{{ ($i18n.locale=='bn') ? profile.father_name_bn : profile.father_name }}</td>
                                  </tr>
                                  <tr>
                                    <th>{{ $t('externalUserIrrigation.mother_name') }}</th>
                                    <td>{{ ($i18n.locale=='bn') ? profile.mother_name_bn : profile.mother_name }}</td>
                                    <th>{{ $t('externalLrcpn.spouse_name') }}</th>
                                    <td>{{ ($i18n.locale=='bn') ? profile.spouse_name_bn : profile.spouse_name }}</td>
                                  </tr>
                                  <tr>
                                    <th>{{ $t('externalLrcpn.occupation') }}</th>
                                    <td>{{ ($i18n.locale=='bn') ? profile.occupation_bn : profile.occupation }}</td>
                                    <th>{{ $t('externalUserIrrigation.date_of_birth') }}</th>
                                    <td>{{ profile.dob | dateFormat }}</td>
                                  </tr>
                                  <tr>
                                    <th>{{ $t('externalUserIrrigation.nid') }}</th>
                                    <td>{{ $n(profile.nid, { useGrouping: false }) }}</td>
                                    <th>{{ $t('globalTrans.mobile') }}</th>
                                    <td>{{ $i18n.locale === 'bn' ? '০' : '0' }}{{ $n(profile.mobile_no, { useGrouping: false }) }}</td>
                                  </tr>
                                  <tr>
                                    <th>{{ $t('externalTraining.email') }}</th>
                                    <td>{{ ($i18n.locale=='bn') ? profile.email : profile.email }}</td>
                                  </tr>
                              </tbody>
                            </table>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col lg="12" sm="12" class="p-0">
                          <div class="card card w-100 text-center">
                              <div class="card-inside-title font-weight-bold text-capitalize p-1 bg-light text-black">
                                <h5 class="font-weight-bold text-capitalize text-center bg-light p-1 badge-light" style="color: green!important;font-size: 20px!important;"> {{ $t('admission_form.add_info') }}</h5>
                              </div>
                          </div>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col lg="12" sm="12" class="p-0">
                            <table class="table">
                              <tbody>
                                  <tr>
                                    <th>{{ $t('org_pro_division.division') }}</th>
                                    <td>{{ ($i18n.locale=='bn') ? profile.division_name_bn : profile.division_name }}</td>
                                    <th>{{ $t('org_pro_district.district') }}</th>
                                    <td>{{ ($i18n.locale=='bn') ? profile.district_name_bn : profile.district_name }}</td>
                                  </tr>
                                  <tr>
                                    <th>{{ $t('org_pro_upazilla.upazilla') }}</th>
                                    <td>{{ ($i18n.locale=='bn') ? profile.upazilla_name_bn : profile.upazilla_name }}</td>
                                  </tr>
                                  <tr>
                                    <th>{{ $t('externalLrcpn.present_address') }}</th>
                                    <td>{{ profile.present_address }}</td>
                                    <th>{{ $t('externalLrcpn.permanent_address') }}</th>
                                    <td>{{ profile.permanent_address }}</td>
                                  </tr>
                              </tbody>
                            </table>
                        </b-col>
                      </b-row>
                  </b-col>
                </b-overlay>
            </template>
          </iq-card>
        </b-col>
      </b-row>
    </b-container>
</div>
</template>
<script>
import { licenseRegistrationServiceBaseUrl } from '@/config/api_config'
import ExportPdf from './export_pdf_details_profile'
export default {
  props: ['details'],
  created () {
    this.profile = this.getRelationalData(this.details)
    this.loadData()
  },
  data () {
    return {
      loading: false,
      baseUrl: licenseRegistrationServiceBaseUrl,
      profile: {}
    }
  },
  computed: {
    genderList: function () {
      return this.$store.state.ExternalUserIrrigation.commonObj.genderList.map(item => {
        if (this.$i18n.locale === 'bn') {
            return { value: item.value, text: item.text_bn }
        } else {
            return { value: item.value, text: item.text_en }
        }
      })
    },
    religionList: function () {
      return this.$store.state.ExternalUserIrrigation.commonObj.religionList.map(item => {
        if (this.$i18n.locale === 'bn') {
            return { value: item.value, text: item.text_bn }
        } else {
            return { value: item.value, text: item.text_en }
        }
      })
    },
    columns () {
        const labels = [
            { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
            { label: this.$t('elearning_iabm.registration_for'), class: 'text-left' },
            { label: this.$t('globalTrans.name'), class: 'text-left' },
            { label: this.$t('globalTrans.email'), class: 'text-left' },
            { label: this.$t('globalTrans.mobile'), class: 'text-left' },
            { label: this.$t('externalUserIrrigation.date_of_birth'), class: 'text-left' },
            { label: this.$t('globalTrans.nid'), class: 'text-left' },
            // { label: this.$t('globalTrans.status'), class: 'text-center' },
            { label: this.$t('globalTrans.action'), class: 'text-center' }
        ]

        let keys = []

        if (this.$i18n.locale === 'bn') {
            keys = [
            { key: 'index' },
            { key: 'user_type_name_bn' },
            { key: 'name_bn' },
            { key: 'email' },
            { key: 'mobile' },
            { key: 'date_of_birth' },
            { key: 'nid' },
            // { key: 'status' },
            { key: 'action' }
            ]
        } else {
            keys = [
            { key: 'index' },
            { key: 'user_type_name' },
            { key: 'name' },
            { key: 'email' },
            { key: 'mobile' },
            { key: 'date_of_birth' },
            { key: 'nid' },
            // { key: 'status' },
            { key: 'action' }
            ]
        }

        return labels.map((item, index) => {
            return Object.assign(item, keys[index])
        })
    }
},
  methods: {
    getRelationalData (data) {
      const division = this.$store.state.ExternalUserIrrigation.commonObj.divisionList.find(division => division.value === data.division_id)
      const district = this.$store.state.ExternalUserIrrigation.commonObj.districtList.find(district => district.value === data.district_id)
      const upazilla = this.$store.state.ExternalUserIrrigation.commonObj.upazilaList.find(upazilla => upazilla.value === data.upazilla_id)

      const divisionObj = { division_name: division !== undefined ? division.text_en : '', division_name_bn: division !== undefined ? division.text_bn : '' }
      const districtObj = { district_name: district !== undefined ? district.text_en : '', district_name_bn: district !== undefined ? district.text_bn : '' }
      const upazillaObj = { upazilla_name: upazilla !== undefined ? upazilla.text_en : '', upazilla_name_bn: upazilla !== undefined ? upazilla.text_bn : '' }

      return Object.assign(data, divisionObj, districtObj, upazillaObj)
    },
    pdfExport () {
      const reportTitle = this.$i18n.locale === 'en' ? 'Profile Info Details' : 'প্রোফাইলের তথ্য বিবরণ'
      ExportPdf.exportPdfDetails(licenseRegistrationServiceBaseUrl, '/config/report-head/detail', 12, reportTitle, this.profile, this)
    }
  }
}
</script>
<style>
  .hidden_header {
    display: none
  }
  .card-border {
    border: 1px solid #b9bdc1;
    box-shadow: 1px 1px 6px -1px grey;
    background-color: #dee2e6;
  }
  [dir=ltr][mode=light] .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 10px;
  }
  .report-name{
    font-weight: bold !important;
    text-transform: uppercase;
  }
  .my-btn{
    padding: 2px !important;
  }
  .table tr, .table tr td, .table tr th {
    border: 1px solid #b9bdc1;
  }
</style>
