<template>
  <b-container fluid>
    <b-row v-if="!isFinalSave">
      <b-col lg="12" sm="12">
        <b-overlay :show="loading">
          <iq-card>
            <template v-slot:body>
              <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
                <b-form @submit.prevent="handleSubmit(saveData)" @reset.prevent="reset" >
                  <template>
                    <div style="font-size:12px; background-color: #337982; padding:6px">
                      <h5 class="text-white text-center"> {{ $t('admission_form.gen_inf') }}</h5>
                    </div>
                  </template>
                  <b-row class="mt-4">
                    <b-col lg="6" md="6" sm="12" xs="12">
                      <ValidationProvider name="Name (En)" vid="name" rules="required">
                        <b-form-group class="row" label-cols-sm="4" label-for="name" slot-scope="{ valid, errors }">
                          <template v-slot:label>
                            {{$t('common_config.farmer_name_en')}} <span class="text-danger">*</span>
                          </template>
                          <b-form-input
                              type="text"
                              id="name"
                              v-model="form.name"
                              :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col lg="6" md="6" sm="12" xs="12">
                      <ValidationProvider name="Name (Bn)" vid="name_bn" rules="required">
                        <b-form-group class="row" label-cols-sm="4" label-for="name_bn" slot-scope="{ valid, errors }">
                          <template v-slot:label>
                            {{$t('common_config.farmer_name_bn')}} <span class="text-danger">*</span>
                          </template>
                          <b-form-input
                              type="text"
                              id="pay_grade"
                              v-model="form.name_bn"
                              :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col lg="6" md="6" sm="12" xs="12">
                      <ValidationProvider name="Father Name (En)" vid="father_name" rules="required">
                        <b-form-group class="row" label-cols-sm="4" label-for="father_name" slot-scope="{ valid, errors }">
                          <template v-slot:label>
                            {{$t('common_config.farmer_father_name_en')}} <span class="text-danger">*</span>
                          </template>
                          <b-form-input
                              type="text"
                              id="pay_grade"
                              v-model="form.father_name"
                              :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col lg="6" md="6" sm="12" xs="12">
                      <ValidationProvider name="Father Name (Bn)" vid="father_name_bn" rules="required">
                        <b-form-group class="row" label-cols-sm="4" label-for="father_name_bn" slot-scope="{ valid, errors }">
                          <template v-slot:label>
                            {{$t('common_config.farmer_father_name_bn')}} <span class="text-danger">*</span>
                          </template>
                          <b-form-input
                              v-model="form.father_name_bn"
                              :state="errors[0] ? false : (valid ? true : null)"
                          >
                          </b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col lg="6" md="6" sm="12" xs="12">
                      <ValidationProvider name="Mother Name (En)" vid="mother_name_en" rules="required">
                        <b-form-group class="row" label-cols-sm="4" label-for="mother_name_en" slot-scope="{ valid, errors }">
                          <template v-slot:label>
                            {{$t('admission_form.mother_name_en')}} <span class="text-danger">*</span>
                          </template>
                          <b-form-input
                              type="text"
                              id="mother_name_en"
                              v-model="form.mother_name"
                              :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col lg="6" md="6" sm="12" xs="12">
                      <ValidationProvider name="Mother Name (Bn)" vid="mother_name_bn" rules="required">
                        <b-form-group class="row" label-cols-sm="4" label-for="mother_name_bn" slot-scope="{ valid, errors }">
                          <template v-slot:label>
                            {{$t('admission_form.mother_name_bn')}} <span class="text-danger">*</span>
                          </template>
                          <b-form-input
                              type="text"
                              id="pay_grade"
                              v-model="form.mother_name_bn"
                              :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col lg="6" md="6" sm="12" xs="12">
                      <ValidationProvider name="Spouse Name (En)" vid="spouse_name">
                        <b-form-group class="row" label-cols-sm="4" label-for="spouse_name" slot-scope="{ valid, errors }">
                          <template v-slot:label>
                            {{ $t('externalLrcpn.spouse_name_en') }}
                          </template>
                          <b-form-input
                              type="text"
                              id="spouse_name"
                              v-model="form.spouse_name"
                              :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col lg="6" md="6" sm="12" xs="12">
                      <ValidationProvider name="Spouse Name (Bn)" vid="spouse_name_bn">
                        <b-form-group class="row" label-cols-sm="4" label-for="spouse_name_bn" slot-scope="{ valid, errors }">
                          <template v-slot:label>
                            {{ $t('externalLrcpn.spouse_name_bn') }}
                          </template>
                          <b-form-input
                              type="text"
                              id="spouse_name_bn"
                              v-model="form.spouse_name_bn"
                              :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col lg="6" md="6" sm="12" xs="12">
                      <ValidationProvider name="Occupation (En)" vid="occupation">
                        <b-form-group class="row" label-cols-sm="4" label-for="occupation" slot-scope="{ valid, errors }">
                          <template v-slot:label>
                            {{ $t('externalLrcpn.occupation_en') }}
                          </template>
                          <b-form-input
                              type="text"
                              id="occupation"
                              v-model="form.occupation"
                              :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col lg="6" md="6" sm="12" xs="12">
                      <ValidationProvider name="occupation (Bn)" vid="occupation_bn">
                        <b-form-group class="row" label-cols-sm="4" label-for="occupation_bn" slot-scope="{ valid, errors }">
                          <template v-slot:label>
                            {{ $t('externalLrcpn.occupation_bn') }}
                          </template>
                          <b-form-input
                              type="text"
                              id="occupation_bn"
                              v-model="form.occupation_bn"
                              :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col lg="6" md="6" sm="12" xs="12">
                      <ValidationProvider name="Date of Birth" vid="dob" rules="required">
                        <b-form-group class="row" label-cols-sm="4" label-for="dob" slot-scope="{ valid, errors }">
                          <template v-slot:label>
                            {{ $t('externalUserIrrigation.date_of_birth') }} <span class="text-danger">*</span>
                          </template>
                          <b-form-input
                              class="date-picker"
                              v-model="form.dob"
                              :placeholder="$t('globalTrans.select_date')"
                              :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col lg="6" md="6" sm="12" xs="12">
                      <ValidationProvider name="NID" vid="nid" rules="required">
                        <b-form-group class="row" label-cols-sm="4" label-for="nid" slot-scope="{ valid, errors }">
                          <template v-slot:label>
                            {{$t('admission_form.nid_no')}} <span class="text-danger">*</span>
                          </template>
                          <b-form-input
                              type="text"
                              id="nid"
                              v-model="form.nid"
                              oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                              :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col lg="6" md="6" sm="12" xs="12">
                      <ValidationProvider name="Email" vid="email">
                        <b-form-group class="row" label-cols-sm="4" label-for="email" slot-scope="{ valid, errors }">
                          <template v-slot:label>
                            {{ $t('admission_form.email') }} <span class="text-danger">*</span>
                          </template>
                          <b-form-input
                              type="email"
                              id="email"
                              v-model="form.email"
                              :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col lg="6" md="6" sm="12" xs="12">
                      <ValidationProvider name="Mobile No" vid="mobile_no" rules="required|digits:11">
                        <b-form-group class="row" label-cols-sm="4" label-for="mobile_no" slot-scope="{ valid, errors }">
                          <template v-slot:label>
                            {{$t('admission_form.mobile_no')}} <span class="text-danger">*</span>
                          </template>
                          <b-form-input
                              type="text"
                              id="mobile_no"
                              v-model="form.mobile_no"
                              oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                              :state="errors[0] ? false : (valid ? true : null)"
                              readonly
                          ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                  </b-row>
                  <template>
                    <div style="font-size:12px; background-color: #337982; padding:6px; margin-bottom:10px;">
                      <h5 class="text-white text-center"> {{ $t('admission_form.add_info') }}</h5>
                    </div>
                  </template>
                  <b-row>
                    <b-col lg="6" sm="12">
                      <ValidationProvider name="Division" vid="division_id" rules="required|min_value:1">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="division_id"
                            slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                            {{ $t('org_pro_division.division')}} <span class="text-danger">*</span>
                          </template>
                          <b-form-select
                              plain
                              v-model="form.division_id"
                              :options="divisionList"
                              id="division_id"
                              :state="errors[0] ? false : (valid ? true : null)"
                          >
                            <template v-slot:first>
                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                          </b-form-select>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col lg="6" sm="12">
                      <ValidationProvider name="District" vid="district_id" rules="required|min_value:1">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="district_id"
                            slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                            {{ $t('org_pro_district.district')}} <span class="text-danger">*</span>
                          </template>
                          <b-form-select
                              plain
                              v-model="form.district_id"
                              :options="districtList"
                              id="district_id"
                              :state="errors[0] ? false : (valid ? true : null)"
                          >
                            <template v-slot:first>
                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                          </b-form-select>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col lg="6" sm="12">
                      <ValidationProvider name="Upazila" vid="upazila_id">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="upazila_id"
                            slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                            {{ $t('org_pro_upazilla.upazilla')}} <span class="text-danger">*</span>
                          </template>
                          <b-form-select
                              plain
                              v-model="form.upazilla_id"
                              :options="upazillaList"
                              id="upazila_id"
                              :state="errors[0] ? false : (valid ? true : null)"
                          >
                            <template v-slot:first>
                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                          </b-form-select>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col lg="6" sm="12">
                      <ValidationProvider name="Present Address" vid="present_address" rules="required">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="present_address"
                            slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                            {{ $t('externalLrcpn.present_address') }} <span class="text-danger">*</span>
                          </template>
                          <b-form-textarea
                              plain
                              v-model="form.present_address"
                              id="present_address"
                              :state="errors[0] ? false : (valid ? true : null)"
                          >
                            <template v-slot:first>
                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                          </b-form-textarea>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col lg="6" sm="12">
                      <ValidationProvider name="Permanent Address" vid="permanent_address" rules="required">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="permanent_address"
                            slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                            {{ $t('externalLrcpn.permanent_address') }} <span class="text-danger">*</span>
                          </template>
                          <b-form-textarea
                              plain
                              v-model="form.permanent_address"
                              id="permanent_address"
                              :state="errors[0] ? false : (valid ? true : null)"
                          >
                            <template v-slot:first>
                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                          </b-form-textarea>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                  </b-row>
                  <b-col class="d-flex justify-content-center" lg="12" sm="6">
                    <b-button-group>
                      <b-button type="submit"
                        variant="warning"
                        :disabled="form.status===2"
                        title="After Draft you can edit"
                        class="ml-1 rounded"
                        @click="isFinal = false"
                      >
                        {{ $t('externalUserIrrigation.save_draft') }} <i class="fas fa-edit ml-1"></i>
                      </b-button>
                      <b-button type="submit"
                          variant="success"
                          title="Final Save cannot be reverted!"
                          class="ml-1 rounded"
                          @click="isFinal = true"
                          >{{ $t('externalUserIrrigation.final_save') }}
                          <i class="fas fa-lock ml-1"></i>
                      </b-button>
                    </b-button-group>
                  </b-col>
                </b-form>
              </ValidationObserver>
            </template>
          </iq-card>
        </b-overlay>
      </b-col>
    </b-row>
    <div v-else>
      <Details :details="details" :key="item"></Details>
    </div>
  </b-container>
</template>
<script>
import Details from './Details'
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { licenseRegistrationServiceBaseUrl } from '@/config/api_config'
import { profileShow, profileStore, profileUpdate } from '../../api/routes'
import flatpickr from 'flatpickr'
import { snakeToWords } from '@/Utils/fliter'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider,
    Details
  },
  data () {
    return {
      loading: false,
      isFinalSave: false,
      saveBtnName: this.$route.query.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      districtList: [],
      upazillaList: [],
      details: {},
      form: {
        name: '',
        name_bn: '',
        father_name: '',
        father_name_bn: '',
        mother_name: '',
        mother_name_bn: '',
        occupation: '',
        occupation_bn: '',
        dob: '',
        nid: '',
        email: '',
        mobile_no: '',
        division_id: 0,
        district_id: 0,
        upazilla_id: 0,
        present_address: '',
        permanent_address: '',
        status: 1
      },
      isFinal: false,
      isUpdate: false
    }
  },
  async created () {
    const tmp = await this.getFarmerInfoData()
    if (Object.keys(tmp).length === 0) {
      this.isFinalSave = false
      this.form = Object.assign({}, this.form, this.commonProfile, {
        user_id: this.authUser.user_id,
        mobile_no: this.commonProfile.mobile_no !== '' ? this.commonProfile.mobile_no : this.authUser.mobile_no
      })
      this.loading = false
    } else if (tmp.status === 1) {
      this.isFinalSave = false
      this.form = tmp
      this.isUpdate = true
    } else {
      this.isFinalSave = true
      this.details = tmp
    }
    this.saveLavel(tmp)
  },
  mounted () {
    flatpickr('.date-picker', {})
    core.index()
  },
  watch: {
    'form.division_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.districtList = this.getDistrictList(newVal)
      }
    },
    'form.district_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.upazillaList = this.getUpazillaList(newVal)
      }
    },
    isFinal: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.form.status = newVal ? 2 : 1
      }
    }
  },
  computed: {
    divisionList: function () {
      return this.$store.state.ExternalUserIrrigation.commonObj.divisionList.filter(item => item.status === 0)
    },
    commonProfile () {
      return this.$store.state.ExternalUserIrrigation.commonProfile
    },
    authUser: function () {
      return this.$store.state.Auth.authUser
    }
  },
  methods: {
    getDistrictList (divisionId = null) {
      const districtList = this.$store.state.ExternalUserIrrigation.commonObj.districtList.filter(item => item.status === 0)
      if (divisionId) {
        return districtList.filter(district => district.division_id === divisionId)
      }
      return districtList
    },
    getUpazillaList (districtId = null) {
      const upazilaList = this.$store.state.ExternalUserIrrigation.commonObj.upazilaList.filter(item => item.status === 0)
      if (districtId) {
        return upazilaList.filter(upazila => upazila.district_id === districtId)
      }
      return upazilaList
    },
    async getFarmerInfoData () {
      this.loading = true
      const params = { user_id: this.authUser.user_id, mobile_no: this.authUser.mobile_no }
      const result = await RestApi.getData(licenseRegistrationServiceBaseUrl, profileShow, params)
      const tmpData = result.success ? result.data : {}
      this.loading = false
      return JSON.parse(JSON.stringify(tmpData))
    },
    onFileChange (e) {
      this.tmpPhoto = e.target.files[0]
    },
    async saveData () {
      this.loading = true
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadingState = { loading: false, listReload: false }
      var formData = new FormData()
      Object.keys(this.form).map(key => {
        formData.append(key, this.form[key])
      })
      this.saveLavel(this.form)
      if (this.isUpdate) {
        formData.append('_method', 'PUT')
        result = await RestApi.postData(licenseRegistrationServiceBaseUrl, `${profileUpdate}/${this.form.id}`, formData)
      } else {
        result = await RestApi.postData(licenseRegistrationServiceBaseUrl, profileStore, formData)
      }
      loadingState.listReload = true
      this.loading = false
      this.$store.dispatch('mutateCommonProperties', loadingState)

      if (result.success) {
        this.$store.commit('mutateIncentiveGrantServiceProperties', { hasDropdownLoaded: false })
        this.$toast.success({
          title: this.$t('globalTrans.success'),
          message: this.form.status === 2 ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })
        if (result.data.status === 2) {
          this.isFinalSave = true
        }
        // this.$store.commit('ExternalLrcpn/setPanelObj', {
        //   lrcpnUserLoader: false,
        //   lrcpnUserStatus: result.data.status
        // })
        location.reload()
      } else {
        this.$refs.form.setErrors(result.errors)
        this.$toast.error({
          title: 'Error',
          message: result.message,
          color: '#D6E09B'
        })
      }
    },
    saveLavel (data) {
      const labels = []
      Object.keys(data).forEach((key) => {
        const obj = {}
        if (key === 'region' || key === 'gender' || key === 'division_id' || key === 'district_id' || key === 'upazilla_id' || key === 'status') {
          const fieldName = 'field_name'
          obj[fieldName] = key
          const fieldType = 'field_type'
          obj[fieldType] = 'Dropdown'
          const labelName = 'label_name'
          obj[labelName] = snakeToWords(key)
          const labelValue = 'value'
          obj[labelValue] = data[key]
          labels.push(obj)
        } else if (key === 'present_address' || key === 'permanent_address') {
          const fieldName = 'field_name'
          obj[fieldName] = key
          const fieldType = 'field_type'
          obj[fieldType] = 'Paragraph'
          const labelName = 'label_name'
          obj[labelName] = snakeToWords(key)
          const labelValue = 'value'
          obj[labelValue] = data[key]
          labels.push(obj)
        } else if (key === 'dob') {
          const fieldName = 'field_name'
          obj[fieldName] = key
          const fieldType = 'field_type'
          obj[fieldType] = 'Date'
          const labelName = 'label_name'
          obj[labelName] = snakeToWords(key)
          const labelValue = 'value'
          obj[labelValue] = data[key]
          labels.push(obj)
        } else {
          const fieldName = 'field_name'
          obj[fieldName] = key
          const fieldType = 'field_type'
          obj[fieldType] = 'InputField'
          const labelName = 'label_name'
          obj[labelName] = snakeToWords(key)
          const labelValue = 'value'
          obj[labelValue] = data[key]
          labels.push(obj)
        }
      })
      this.$store.commit('ExternalLrcpn/setLrcpnUserlabel', labels)
    }
  }
}
</script>
